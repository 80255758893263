<template>
  <v-card
    v-if="!loading"
    class="ma-2"
  >
    <v-container fluid>
      <div class="d-flex flex-row pb-3">
        <BaseBtn
          x-small
          color="primary"
          :title="$t('base.save')"
          @click="$emit('save')"
          v-if="$can(null, 'editPassport') && $can('admin') || $can('manager')"
          icon="mdi-content-save"
        />
        <BaseBtn
          x-small
          color="primary"
          @click="$emit('delete')"
          icon="mdi-delete"
          v-if="!!detail.properties.id && ($can(null, 'editPassport') && $can('admin') || $can('manager'))"
          :title="$t('base.delete')"
        />
      </div>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div
            class="d-flex flex-wrap align-center"
            :class="$route.name !== 'map' ? 'mb-10': 'mb-5'"
          >
            <v-card
              outlined
              class="mr-2"
              v-if="$can('admin')"
            >
              <v-card-text class="d-flex flex-column align-center">
                <BaseColorPicker
                  v-model="detail.properties.style"
                  type="passport"
                  @toggleDialog="$emit('toggleDialog', $event)"
                />
                <div>{{ $t('passport.colorOnMap') }}</div>
              </v-card-text>
            </v-card>
            <div class="flex-grow-1 pr-2">
              <BaseRegionSelect
                v-model="detail.properties.region"
                leafs-only
                return-object
                :disabled="!isCanInteractionPassport"
              />
            </div>
          </div>
          <div>
            <v-select
              dense
              outlined
              v-model="detail.properties.type"
              :items="passportTypes"
              :disabled="!isCanInteractionPassport"
              item-value="value"
              item-text="label"
              class="mr-3 d-block"
              :label="$t('passport.type')"
            ></v-select>
            <v-text-field
              v-model="detail.properties.object_name"
              :disabled="!isCanInteractionPassport"
              :label="$t('passport.objectName')"
              class="mr-3 d-block"
              outlined
              dense
              :error-messages="checkErrors('detail.properties.object_name')"
            ></v-text-field>
          </div>
          <div class="d-flex flex-wrap">
            <v-text-field
              v-model="detail.properties.id"
              :label="$t('passport.id')"
              class="mr-3"
              outlined
              dense
              :disabled="!isCanInteractionPassport"
            ></v-text-field>
            <v-text-field
              v-model="detail.properties.cadastr_number"
              :disabled="!isCanInteractionPassport"
              :label="$t('passport.cadastr_number')"
              class="mr-3"
              outlined
              dense
              :error-messages="checkErrors('detail.properties.cadastr_number')"
            ></v-text-field>
          </div>
          <div class="d-flex flex-wrap">
            <v-text-field
              v-model="detail.properties.length"
              :disabled="!isCanInteractionPassport"
              :label="$t('passport.length')"
              class="mr-3"
              outlined
              dense
              type="number"
              :error-messages="checkErrors('detail.properties.length')"
            ></v-text-field>
            <v-text-field
              v-model="detail.properties.width"
              :disabled="!isCanInteractionPassport"
              :label="$t('passport.width')"
              class="mr-3"
              outlined
              dense
              type="number"
              :error-messages="checkErrors('detail.properties.width')"
            ></v-text-field>
            <v-text-field
              v-model="detail.properties.area"
              :disabled="!isCanInteractionPassport"
              :label="$t('passport.area')"
              class="mr-3"
              outlined
              dense
              type="number"
              :error-messages="checkErrors('detail.properties.area')"
            ></v-text-field>
          </div>
          <div class="d-flex flex-wrap">
            <v-text-field
              v-model="detail.properties.person_in_charge"
              :disabled="!isCanInteractionPassport"
              :label="$t('passport.person_in_charge')"
              class="mr-3"
              outlined
              dense
            ></v-text-field>
            <BaseDatePicker
              v-model="detail.properties.origin_passportization_date"
              :disabled="!isCanInteractionPassport"
              :label="$t('passport.origin_passportization_date')"
            />
          </div>
          <div class="d-flex flex-wrap">
            <v-textarea
              v-model="detail.properties.territorial_affiliation"
              :disabled="!isCanInteractionPassport"
              :label="$t('passport.territorial_affiliation')"
              class="mr-3"
              outlined
              dense
              :error-messages="checkErrors('detail.properties.territorial_affiliation')"
            ></v-textarea>
            <v-textarea
              v-model="detail.properties.land_function"
              :disabled="!isCanInteractionPassport"
              :label="$t('passport.land_function')"
              class="mr-3"
              outlined
              dense
              :error-messages="checkErrors('detail.properties.land_function')"
            ></v-textarea>
          </div>
          <div class="d-flex flex-wrap">
            <v-textarea
              v-model="detail.properties.executing_organization"
              :disabled="!isCanInteractionPassport"
              :label="$t('passport.executing_organization')"
              class="mr-3"
              outlined
              dense
              :error-messages="checkErrors('detail.properties.executing_organization')"
            ></v-textarea>
            <v-textarea
              v-model="detail.properties.owner"
              :disabled="!isCanInteractionPassport"
              :label="$t('passport.owner')"
              class="mr-3"
              outlined
              dense
              :error-messages="checkErrors('detail.properties.owner')"
            ></v-textarea>
          </div>
          <div class="d-flex">
            <v-text-field
              v-model="detail.properties.classification_code"
              :disabled="!isCanInteractionPassport"
              :label="$t('passport.classification_code')"
              class="mr-3"
              outlined
              dense
              :error-messages="checkErrors('detail.properties.classification_code')"
            ></v-text-field>
          </div>
          <div class="d-flex flex-wrap">
            <v-textarea
              v-model="detail.properties.classification_code_detail"
              :disabled="!isCanInteractionPassport"
              :label="$t('passport.classification_code_detail')"
              class="mr-3"
              outlined
              dense
              :error-messages="checkErrors('detail.properties.classification_code_detail')"
            ></v-textarea>
            <v-textarea
              v-model="detail.properties.using_mode"
              :disabled="!isCanInteractionPassport"
              :label="$t('passport.using_mode')"
              class="mr-3"
              outlined
              dense
              :error-messages="checkErrors('detail.properties.using_mode')"
            ></v-textarea>
          </div>
          <div class="d-flex flex-wrap">
            <v-textarea
              v-model="detail.properties.address"
              :disabled="!isCanInteractionPassport"
              :label="$t('passport.address')"
              class="mr-3"
              outlined
              dense
            ></v-textarea>
            <v-textarea
              v-model="detail.properties.origin_executor"
              :disabled="!isCanInteractionPassport"
              :label="$t('passport.origin_executor')"
              class="mr-3"
              outlined
              dense
            ></v-textarea>
          </div>
          <!--<BaseCoordinates
            v-if="$route.name !== 'map'"
            v-model="detail.geometry"
            :disabled="!$can(null, 'editPassport')"
            class="ml-0 mr-1 pt-2"
            :label="'Координаты полигона'"
            :error-messages="checkErrors('detail.geometry.coordinates')"
          />-->
        </v-col>
      </v-row>
    </v-container>
  </v-card>
  <BasePreloader v-else/>
</template>
<script>
import validationErrors from '@/components/mixins/validationErrors'
import BasePreloader from '@/components/base/UI/BasePreloader'
import BaseCoordinates from '@/components/base/BaseCoordinates'
import BaseColorPicker from '@/components/base/BaseColorPicker'
import BaseRegionSelect from '@/components/base/forms/BaseRegionSelect'
import BaseDatePicker from '@/components/base/BaseDatePicker'
import BaseBtn from '@/components/base/UI/BaseBtn'
import userInteraction from '@/components/mixins/userInteraction'

export default {
  mixins: [validationErrors, userInteraction],
  components: { BaseColorPicker, BaseCoordinates, BasePreloader, BaseRegionSelect, BaseDatePicker, BaseBtn },
  name: 'DetailView',
  props: {
    value: Object,
    loading: Boolean
  },
  data () {
    return {
      detail: {}
    }
  },
  inject: ['$v'],
  computed: {
    passportTypes() {
      return this.$store.getters['serviceData/get']('passport.type') || []
    }
  },
  watch: {
    value: {
      handler () {
        this.detail = this.value
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.$v.$touch()
  }
}
</script>

<style scoped>

</style>
