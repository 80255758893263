<template>
  <BaseCard
    v-if="detail"
    is-small-height
  >
    <template #content>
      <DetailView
        @save="$emit('save')"
        @delete="$emit('delete')"
        :loading="isLoading"
        v-model="detail"
        @toggleDialog="$emit('toggleDialog', $event)"
      />
    </template>
  </BaseCard>
</template>

<script>
import DetailView from '@/components/views/account/passport/detail/views/passportDetail/DetailView.vue'
import BaseCard from '@/components/base/BaseCard'

export default {
  name: 'MapEdit',
  components: { BaseCard, DetailView },
  props: {
    value: Object,
    isLoading: Boolean
  },
  data () {
    return {
      detail: this.value
    }
  },
  watch: {
    value: {
      handler () {
        this.detail = this.value
      },
      deep: true,
      immediate: true
    }
  },
  inject: ['$v']
}
</script>

<style scoped>

</style>
